<template>
  <div class="caption" style="min-height: 20px">
    <v-icon
      v-if="help"
      class="primary--text"
      x-small
      left
    >
      fa fa-info-circle
    </v-icon>

    <span
      v-if="help"
      class="primary--text"
    >
      {{help}}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    formKey: String,
    formDataItem: Object,
  },
  computed: {
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
    help() {
      if(!this.formDataItem.help) return null
      if(typeof this.formDataItem.help != 'function') {
        return this.$t(this.formDataItem.help)
      }
      return this.$t(this.formDataItem.help(this.formInfo))
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>